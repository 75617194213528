<template>
  <v-app>
    <AxiosPreloaderAndResponse />
    <ModalTransferChatUser />

    <router-view></router-view>
  </v-app>
</template>

<script>
import AxiosPreloaderAndResponse from './components/AxiosPreloaderAndResponse.vue';
import { mapGetters } from 'vuex';
import HELPERS from '@/modules/OperatorPanel/helpers';
import i18n from "@/lang/i18n";
import ModalTransferChatUser from "@/modules/OperatorPanel/views/chats/components/ModalTransferChatUser.vue";
import Vue from "vue";

export default {
  name: 'MainApp',

  sockets: {
    notification(data) {
      const options = {
        position: 'top-right',
        timeout: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        closeButton: 'button',
        icon: true,
      };

      let message = data.message;

      if (data.message_alias) {
        switch (data.message_alias) {
          case 'broadcast_success':
            message = this.$t('notification.broadcast_success');

            break;

          case 'broadcast_error':
            message = this.$t('notification.broadcast_error');

            break;
        }
      }

      this.$toast(message, { ...options, ...data.options });
    },

    new_message(data) {
      this.messagesListener(data);
    },

    chat_room_created(data) {
      this.rulesPlayNotification(data)
    },

    chat_room_update(data) {
      this.rulesPlayNotification(data)
    },

    participants_connected(data){
      //notification if added me
      if(this.opUserInfo?.id === data.data?.user_id) {
        this.playNotification(this.$t('modules.op.texts.participant_added'))
      }
    },

    connect_error(err) {
      HELPERS.handleError([err, i18n.t("common.error"), i18n.t("common.error_network")]);
    },

    connect() {
      this.$socket.client.emit('login', this.opUserInfo?.id);
      this.$socket.client.emit('joinRoom', 'engine');
    },

    //chat transfers
    transfers(data) {
      this.setChatRoomTransfers(data)
    },
    send_transfer_chat(data) {
      this.sendTransferChat(data)
    },
    transfer_cancelled(data) {
      this.transferCanceled(data)
    },
    transfer_accepted(data) {
      this.transferAccepted(data)
    },
    transfer_cancelled_by_timeout(data){
      this.transferCancelledByTimeout(data)
    },
  },

  beforeCreate() {

    //set language for html attr lang from cookie
    let user_lang = this.$getCookie('user_lang')
    if(user_lang){
      document.documentElement.setAttribute("lang", user_lang);
    }
  },

  created() {
    if(!Vue.$cookies.get("token_c") && this.$route?.name !== 'Login')
      this.$router.push({ name: 'Login' });

    window.onbeforeunload = () => {
      if (this.opUserInfo && this.bot_settings?.active_id) {
        if(typeof this.opUserInfo?.settings_json !== 'object' || this.opUserInfo?.settings_json?.reset_status === true) {
          this.$store.dispatch('opSetUserStatus', {
            status_id: 2,
            bot_id: this.bot_settings.active_id,
          });
        }
      }
    };

    //replaced to Layout
    // this.$store.dispatch('OpAxiosGetMyInfo');

    /* for DEBUGGING State */
    // this.$store.subscribeAction((action, state) => {
    //   console.log('ACTIONNN SUBSCRIBER', action.type,)
    // })
    // this.$store.subscribe((mutation, state) => {
    //   console.log('MUTATION SUBSCRIBER', mutation)
    // });
  },

  computed: {
    ...mapGetters([
      'opChatRooms',
      'opActiveChatRoom',
      'opActiveChatRoomFromState',
      'opUserInfo',
      'bot_settings',
      'opSubjectList',
    ]),
  },

  watch: {
    //TODO: strange code
    opUserInfo: function (user) {
      if (user?.id) {
        this.$socket.client.emit('login', user.id);
        this.$socket.client.emit('joinRoom', 'engine');
      }
    },

    /* for DEBUGGING Route */
    // '$route' (to, from) {
    //   console.log('Route changed from ' + from.path + ' to ' + to.path);
    // },
  },

  methods: {
    async messagesListener(event) {
      const message = event.data;

      if (message?.system || message?.isUser) {

        //save msg in Active Dialog if isUser
        if (( message.isUser || message?.system ) && this.opActiveChatRoom?.id === event.data.chat_room_id) {
          await this.$store.dispatch('opAddMessageToActiveChatRooms', event);
        }
        //save msg in Active Dialog if isUser

        return;
      }

      const authorId = message?.participant_id;
      const text = message?.message_text;
      const roomId = message?.chat_room_id;

      const isEqualAuthorId = (participant) => participant.id === authorId;

      const isExistInChatRooms = (room) => room.id === roomId;

      const getChatRoom = (room) => {
        const participants = room.participants || [];
        return participants.some(isEqualAuthorId);
      };

      const getParticipant = () => {
        const chatRoom = this.opChatRooms.find(getChatRoom);

        if (chatRoom === undefined) {
          return null;
        }

        const participant = chatRoom.participants.find(isEqualAuthorId);

        return participant;
      };

      const authorObj = getParticipant(authorId);

      //return if room not exist in Rooms
      if (!this.opChatRooms.find(isExistInChatRooms)) return

      if (authorObj?.user_id === this.opUserInfo.id) {
        return;
      }

      const author = authorObj?.client || authorObj?.user;

      const authorName = HELPERS.formatClientName(author);

      //check if user exist in participants of the chat
      if(this.opChatRooms.find(isExistInChatRooms)?.participants?.length){
        const isMeInChat = this.opChatRooms.find(isExistInChatRooms)?.participants
          .some(({user_id}) => user_id && user_id === this.opUserInfo.id)

        if (isMeInChat) {
          await this.playNotification(authorName, text);
        }
      }

      const urlParams = new URLSearchParams(window.location.search);

      if (
        (!this.opActiveChatRoom?.id && !urlParams.get('chat_room_id'))
        || (+urlParams.get('chat_room_id') !== event.data.chat_room_id)
      ) {
        this.$store.dispatch('opSetChatNotification', event);
      } else if (this.opActiveChatRoom?.id === event.data.chat_room_id) {
        this.$store.dispatch('opAddMessageToActiveChatRooms', event);
      }

      // if (
      //   (!this.opActiveChatRoom?.id && !urlParams.get('chat_room_id')) ||
      //   (urlParams.get('chat_room_id') &&
      //     this.opActiveChatRoom?.id !== +urlParams.get('chat_room_id'))
      // ) {
      //   this.$store.dispatch('opSetChatNotification', event);
      // }
    },

    //chat transfers
    setChatRoomTransfers(data) {
      const transfers = data?.data?.transfers

      //set transfers in state
      this.$store.dispatch('setChatRoomTransfers', transfers)

      if(Object.keys(transfers).length) {
        for(let i in transfers) {
          if(Number(i) === this.opUserInfo.id)
            this.sendTransferChat({
              data: transfers[i],
              transfers: transfers,
              withoutTimer: true,
            })
        }
      }
    },
    async sendTransferChat(data) {
      await this.$store.dispatch('setChatRoomTransfers', data.transfers)

      if(data.data.user_id_receive === this.opUserInfo.id) {
        await this.$store.dispatch('opSetTransferChatUserModalOpen', {
          open: true,
          data: data.data,
          withoutTimer: data?.withoutTimer || null
        })

        await this.playNotification(this.$t('modules.op.transfer_modal.title'), data?.system_message)
      }
    },
    transferCanceled(data) {
      //set transfers in state
      this.$store.dispatch('setChatRoomTransfers', data.transfers)

      if(data.data.user_id_sender === this.opUserInfo.id) {
        this.$store.dispatch('opSetTransferChatUserModalOpen', {
          open: false,
          data: null
        })
        // this.$store.dispatch('opSetTransferDisabled', false);
        this.$toast(this.$t('modules.op.transfer_modal.operator_cancel_transfer'));
      }

      if(data.data.user_id_receive === this.opUserInfo.id) {
        this.$store.dispatch('opSetTransferChatUserModalOpen', {
          open: false,
          data: null
        })

        this.$toast(this.$t('modules.op.transfer_modal.transfer_is_canceled'));
      }
    },
    transferAccepted(data) {
      //set transfers in state
      this.$store.dispatch('setChatRoomTransfers', data.transfers)

      if(data.data.user_id_sender === this.opUserInfo.id) {
        // this.$store.dispatch('opSetTransferDisabled', false);
        this.$toast(this.$t('modules.op.transfer_modal.operator_accept_dialog'));

        //exit from dialog if it activated
        if (this.opActiveChatRoomFromState === data.data.chat_room_id) {
          this.$store.dispatch('opClearActiveChat');

          this.$store.dispatch('updateAjaxDialog', [
            true,
            false,
            '',
            this.$t('modules.op.texts.chat_exited'),
          ]);
        }
      }
    },

    transferCancelledByTimeout(data) {
      this.$store.dispatch('setChatRoomTransfers', data.transfers)

      if(data.data.user_id_receive === this.opUserInfo.id) {
        this.$store.dispatch('opSetTransferChatUserModalOpen', {
          open: false,
          data: null
        })
        this.$nextTick(() => {
          this.$toast(this.$t('modules.op.transfer_modal.transfer_is_canceled'));
        })
      }

      if(data.data.user_id_sender === this.opUserInfo.id) {
        this.$toast(this.$t('modules.op.transfer_modal.transfer_is_canceled'));
      }
    },

    async rulesPlayNotification(data) {
      const chatRoom = data?.data

      //Check if dialog not available for me
      if (chatRoom?.subject_id) {
        const even = ({id}) => id === chatRoom.subject_id;
        if ( !this.opSubjectList?.some(even) ) return
      }
      //end Check if dialog not available for me

      await this.$store.dispatch('opSetChatNotification', 'update');

      if (chatRoom?.status === 'new' && !chatRoom?.from_operator) {
        await this.playNotification(this.$t('modules.op.texts.new_dialog'))
        if(this.opUserInfo?.active_status?.can_receive) {
          this.$toast(this.$t('modules.op.texts.new_dialog'))
        }
      } else if(
        chatRoom?.status === 'processed' &&
        chatRoom?.extra_data?.auto_connected === this.opUserInfo?.id
      ) {
        await this.playNotification()
      }
    },

    async playNotification(title = null, text = null) {
      //notifications only for online status
      if(!this.opUserInfo?.active_status?.can_receive) return

      const settings_json =  this.opUserInfo?.settings_json

      //play Notification if NOT Disabled settings
      if (!settings_json?.notifications_browser_disabled || settings_json?.notifications_browser_disabled !== true ) {
        await HELPERS.showBrowserNotification({
          title: title || this.$t('modules.op.texts.new_message'),
          body: text || '',
        });
      }

      //play Sound if Enabled settings
      if (settings_json?.notifications_sound_enabled) {
        const soundMessageFile = "/notification-message.mp3"
        try {
          const audioSound= new Audio()
          audioSound.src = soundMessageFile
          await audioSound.load()
          await audioSound.play();
        } catch(e) {
          console.log(e)
        }
      }
    }
  },

  components: {
    AxiosPreloaderAndResponse,
    ModalTransferChatUser,
  },
};
</script>
